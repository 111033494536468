// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var closeTimeout = "1000";
export var ModalPlaceholder = "V_cR";
export var ModalContent = "V_cS";
export var closeButton = "V_cT";
export var ModalOverlay = "V_cV";
export var visibleOverlay = "V_cW";
export var fadeIn = "V_cX";
export var hiddenOverlay = "V_cY";
export var fadeOut = "V_cZ";